.statConn {
    display: flex;
    justify-content: center;
    min-width: 15rem !important;
}

.containerConn{
    /* border: 1px solid black; */
}

@media (min-width: 781px) {
    .statConn {
        min-width: 14.5rem !important;
    }
}

@media (min-width: 992px) {
    .statConn {
        min-width: 15rem !important;
    }
}

@media (min-width: 1311px) {
    .statConn {
        min-width: 16rem !important;
    }
}

