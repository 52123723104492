.bodyHome {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    height: auto;
    margin: 1rem auto 0 auto;
}

.spaceCharts {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    grid-template-rows: 100%;
    grid-gap: 1.5rem;
    width: 91%;
    margin: auto;
}


@media (max-width: 780px) {
    .spaceCharts {
        grid-template-columns: 100%;
        grid-template-rows: 33% 33% 33%;
        grid-gap: 1rem;
        width: 100%;
    }

    .bodyHome {
        width: 100%;
        margin-top: 2rem;
    }
}

@media (min-width: 992px) {
    .spaceCharts div {
        height: auto;
        padding-bottom: 1rem;
    }
}